import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = () => {
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [marginLeft, setMarginLeft] = useState('0');
    const sidebarWidth = 256; // 64 * 4 = 256px (w-64 in Tailwind)
    const mobileSidebarWidth = 192; // 48 * 4 = 192px (w-48 in Tailwind)

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth < 768;
            setIsMobile(newIsMobile);
            updateMarginLeft(newIsMobile);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        updateMarginLeft(isMobile);
    }, [location, isSidebarOpen, isMobile]);

    const updateMarginLeft = (mobile:any) => {
        if (isSidebarOpen) {
            setMarginLeft(mobile ? '0' : `${sidebarWidth}px`);
        } else {
            setMarginLeft('0');
        }
    };

    const getTopBarTitle = (pathname:string) => {
        switch (pathname) {
            case '/':
                return 'Dashboard';
            case '/users':
                return 'Users';
            case '/deposit':
                return 'Deposits';
            case '/house':
                return 'House Edge';
            case '/withdraw':
                return 'Withdraw';
            case '/wallet':
                return 'User Wallet';
            case '/history':
                return 'History';
            case '/bonus':
                    return 'Bonus';
            default:
                return 'App';
        }
    };

    return (
        <div className='flex h-screen bg-gray-100 overflow-hidden'>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
        <div className='flex-1 flex flex-col overflow-hidden'>
            <div className="bg-[#37384A] text-white text-2xl font-semibold p-4 sticky top-0 z-10 text-center">
                {getTopBarTitle(location.pathname)}
            </div>
            <div 
                style={{ 
                    // width: `calc(100% - ${isSidebarOpen ? (isMobile ? mobileSidebarWidth : sidebarWidth) : 0}px)`,
                    // marginLeft: marginLeft,
                    // transition: 'margin-left 300ms, width 300ms',
                }} 
                className='flex-1 overflow-auto bg-[#161B2B] p-4 md:p-10'
            >
                <Outlet />
            </div>
        </div>
    </div>
    );
};

export default Layout;