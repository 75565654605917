import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";

// API Constants
const API_URL_USERBONUS = "https://api.aghori.games/bonus/admin/userbonus";
const API_URL_BONUSGAME = "https://api.aghori.games/bonus/admin/bonusgame";
const ROLE_KEY = "3c95e1f2a6b8d740c9e3812f5d7694b0a2c8157e943fd6802b5e9c71m4a3h8p9";

// Unified Type Definition
interface BonusData {
  userid: number;
  name?: string;
  vipbonus?: number;
  specialbonus?: number;
  generalbonus?: number;
  totalbonus?: number;
  createdat: string;
  updatedat: string;
  luckyspin?: number;
  dailybonus?: number;
  weeklybonus?: number;
  monthlybonus?: number;
  depositbonus?: number;
  rollcompetitionbonus?: number;
}

// Styled components for modern look
const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "#2a2a3c",
  color: "white",
  borderRadius: "8px",
  marginTop: "16px",
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "#2a2a3c",
    color: "white",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "500px",
  },
});

const Bonus: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"userBonus" | "bonusGame">("userBonus");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [userBonuses, setUserBonuses] = useState<BonusData[]>([]);
  const [bonusGames, setBonusGames] = useState<BonusData[]>([]);
  const [selectedBonus, setSelectedBonus] = useState<BonusData | null>(null);

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const fetchData = async () => {
    try {
      const endpoint = activeTab === "userBonus" ? API_URL_USERBONUS : API_URL_BONUSGAME;
      const response = await axios.get<BonusData[]>(endpoint, {
        headers: { "role-key": ROLE_KEY },
      });
      activeTab === "userBonus"
        ? setUserBonuses(response.data)
        : setBonusGames(response.data);
    } catch (error) {
      console.error(`Failed to fetch ${activeTab} data`, error);
    }
  };

  const handleUpdateBonus = async (updatedBonus: BonusData) => {
    try {
      const endpoint = activeTab === "userBonus" ? API_URL_USERBONUS : API_URL_BONUSGAME;
      await axios.put(`${endpoint}/${updatedBonus.userid}`, updatedBonus, {
        headers: { "role-key": ROLE_KEY },
      });
      fetchData(); // Real-time refresh after update
      setSelectedBonus(null);
    } catch (error) {
      console.error("Failed to update bonus", error);
    }
  };

  const filterData = (data: BonusData[]) => {
    return data.filter((item) =>
      activeTab === "userBonus"
        ? (item.name || "").toLowerCase().includes(searchTerm.toLowerCase())
        : (item.name || "").toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const renderUserBonusTable = () => (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {["User ID", "Name", "Total Bonus", "VIP Bonus", "Special Bonus", "General Bonus", "Actions"].map((header) => (
              <TableCell key={header} style={{ color: "white", fontWeight: "bold" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterData(userBonuses).map((bonus) => (
            <TableRow key={bonus.userid} hover>
              <TableCell  style={{color:'white'}}>{bonus.userid}</TableCell>
              <TableCell  style={{color:'white'}}>{bonus.name || "N/A"}</TableCell>
              <TableCell  style={{color:'white'}}>
                {(bonus.vipbonus || 0) + (bonus.specialbonus || 0) + (bonus.generalbonus || 0)}
              </TableCell>
              <TableCell  style={{color:'white'}}>{bonus.vipbonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{bonus.specialbonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{bonus.generalbonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedBonus(bonus)}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  const renderBonusGamesTable = () => (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {["User ID", "Name", "Lucky Spin", "Daily Bonus", "Weekly Bonus", "Monthly Bonus", "Deposit Bonus", "Roll Competition Bonus", "Actions"].map(
              (header) => (
                <TableCell key={header} style={{ color: "white", fontWeight: "bold" }}>
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterData(bonusGames).map((game) => (
            <TableRow key={game.userid} hover>
              <TableCell style={{color:'white'}}>{game.userid}</TableCell>
              <TableCell  style={{color:'white'}}>{game.name || "N/A"}</TableCell>
              <TableCell  style={{color:'white'}}>{game.luckyspin || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{game.dailybonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{game.weeklybonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{game.monthlybonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{game.depositbonus || 0}</TableCell>
              <TableCell  style={{color:'white'}}>{game.rollcompetitionbonus || 0}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedBonus(game)}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  return (
    <div className="ml-0 lg:ml-[256px] p-2">
      <Box sx={{ padding: 2, backgroundColor: "#1e1e2d", color: "white", borderRadius: "8px" }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Bonus Management
        </Typography>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "space-between",
            },
            "& .MuiTab-root": {
              color: "white",
              textTransform: "none",
              fontSize: "16px",
            },
            "& .Mui-selected": {
              color: "#90caf9",
            },
          }}
        >
          <Tab value="userBonus" label="User Bonuses" />
          <Tab value="bonusGame" label="Bonus Games" />
        </Tabs>
        <Box sx={{ marginY: 2 }}>
          <TextField
            fullWidth
            placeholder="Search by Name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">🔍</InputAdornment>,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#2a2a3c",
                color: "white",
              },
            }}
          />
        </Box>
        {activeTab === "userBonus" ? renderUserBonusTable() : renderBonusGamesTable()}
        <StyledDialog open={!!selectedBonus} onClose={() => setSelectedBonus(null)}>
          <DialogTitle>Edit Bonus</DialogTitle>
          <DialogContent>
            {selectedBonus && (
              <>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Name"
                  value={selectedBonus.name || ""}
                  onChange={(e) =>
                    setSelectedBonus({
                      ...selectedBonus,
                      name: e.target.value,
                    })
                  }
                  sx={{ "& .MuiInputBase-root": { color: "white" } }}
                />
                {activeTab === "userBonus" ? (
                  <>
                    <TextField
                      fullWidth
                      margin="dense"
                      label="VIP Bonus"
                      type="number"
                      value={selectedBonus.vipbonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          vipbonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Special Bonus"
                      type="number"
                      value={selectedBonus.specialbonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          specialbonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="General Bonus"
                      type="number"
                      value={selectedBonus.generalbonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          generalbonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Lucky Spin"
                      type="number"
                      value={selectedBonus.luckyspin || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          luckyspin: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Daily Bonus"
                      type="number"
                      value={selectedBonus.dailybonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          dailybonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Weekly Bonus"
                      type="number"
                      value={selectedBonus.weeklybonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          weeklybonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Monthly Bonus"
                      type="number"
                      value={selectedBonus.monthlybonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          monthlybonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Deposit Bonus"
                      type="number"
                      value={selectedBonus.depositbonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          depositbonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Roll Competition Bonus"
                      type="number"
                      value={selectedBonus.rollcompetitionbonus || 0}
                      onChange={(e) =>
                        setSelectedBonus({
                          ...selectedBonus,
                          rollcompetitionbonus: Number(e.target.value),
                        })
                      }
                      sx={{ "& .MuiInputBase-root": { color: "white" } }}
                    />
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleUpdateBonus(selectedBonus!)}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button onClick={() => setSelectedBonus(null)} variant="outlined" color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </StyledDialog>
      </Box>
    </div>
  );
};

export default Bonus;
