import React, { useState, useEffect } from "react";
import axios from "axios";

interface TransactionItem {
  id: string;
  user_name: string;
  type: string;
  bet_money: number;
  win_money: number;
  user_balance: number;
}

const History = () => {
  const [transactionData, setTransactionData] = useState<TransactionItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<'live' | 'slot'>('live');

  useEffect(() => {
    fetchTransactionData();
  }, [activeTab]);

  const fetchTransactionData = async () => {
    try {
      const response = await axios.get(`https://api.aghori.games/transaction/${activeTab}`);
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setTransactionData(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setTransactionData([]);
      }
    } catch (error) {
      console.error('Failed to fetch transaction data:', error);
      setTransactionData([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTransactionData = searchTerm
    ? transactionData.filter((transaction) => 
        String(transaction.id).includes(searchTerm) || 
        transaction.user_name.toLowerCase().includes(searchTerm.toLowerCase()))
    : transactionData;

  return (
    <div className='ml-0 lg:ml-[256px]'>
      <div className="mb-4">
        <div className="flex mb-4">
          <button
            onClick={() => setActiveTab('live')}
            className={`p-2 border rounded mr-2 ${activeTab === 'live' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Live
          </button>
          <button
            onClick={() => setActiveTab('slot')}
            className={`p-2 border rounded ${activeTab === 'slot' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Slot
          </button>
        </div>
        <input
          type="text"
          placeholder="Search by name or ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="h-[690px] overflow-scroll">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User Name</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Game</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Bet Money</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Win Money</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User Balance</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactionData.map((transaction, index) => (
              <tr key={transaction.id}>
                <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200">{transaction.user_name}</td>
                <td className="py-3 px-4 border-b border-gray-200">{transaction.type}</td>
                <td className="py-3 px-4 border-b border-gray-200">${transaction.bet_money}</td>
                <td className="py-3 px-4 border-b border-gray-200">${transaction.win_money}</td>
                <td className="py-3 px-4 border-b border-gray-200">${transaction.user_balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default History;
